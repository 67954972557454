@import '../../_variables.scss';

.unicef-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #e4e4e47d;
    backdrop-filter: blur(10px);
    overflow: hidden;

    &-window {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 400px;
        max-width: 600px;
        padding: 40px;
        background-color: #ffffff;

        > h2 {
            margin: 0 0 24px 0;
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: #41a83e;

            @media (max-width: $sm) {
                & {
                    font-size: 24px;
                }
            }
        }

        > p {
            margin: 0 0 32px 0;
            font-size: 18px;
            text-align: center;
        }

        > button {
            padding: 8px 20px;
            border: 0;
            background-color: #41a83e;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
}