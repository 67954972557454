.trainings-control-page {
    min-height: 80vh;
    margin: 40px 0;

    > table {
        width: 100%;
        margin: 0 0 40px 0;
        border-collapse: separate;
        border-spacing: 0 8px;
        table-layout: fixed;
        
        tr :is(th, td) {
            background-color: #ffffff;
            padding: 12px 8px;
        }
    
        thead {
            th {
                background-color: #e2e1e1;
                text-align: left;
                font-weight: 600;
            }
        }
    }
}
