@import "../../../_variables.scss";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;

    &-window {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 400px;
        max-width: 600px;
        padding: 40px;
        background-color: #ffffff;

        > h2 {
            margin: 0 0 24px 0;
            font-size: 28px;
            font-weight: 600;
            text-align: center;
            color: #006ab6;

            @media (max-width: $sm) {
                & {
                    font-size: 24px;
                }
            }
        }

        > p {
            margin: 0 0 12px 0;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
        }

        > p:last-child {
            margin: 0 0 12px 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 120%;
            text-align: center;
            color: #006ab6;
        }
    }
}
