@import "../../../../variables";

.input-date {
    width: 100%;
    padding: 8px 10px 0 10px;
    margin: 0 0 12px 0;
    border: 1px solid $color-light-grey;
    border-radius: 8px;
    background-color: #ffffff;
    font-size: 14px;

    &-label {
        font-weight: 500;
        color: $color-main;
        &-required {
            color: #ff0000;
        }
    }

    input {
        width: 100%;
        min-height: 32px;
        margin: 0;
        border: 0;
        background-color: #ffffff;
        cursor: pointer;
    }

    > .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker {
        border: 1px solid #2b92f1;
        border-radius: 0;
    }

    .react-datepicker__triangle::before {
        border-bottom-color: #2b92f1 !important;
    }

    .react-datepicker__triangle::after {
        border-bottom-color: #d5e9fc !important;
    }

    .react-datepicker__header {
        border-radius: 0;
        background-color: rgb(43, 146, 241, 0.2) !important;
    }

    .react-datepicker__day {
        border-radius: 0;
    }

    .react-datepicker__day--selected {
        background-color: #2b92f1;
        color: #000000;
        font-weight: bold;
    }

    .react-datepicker__day--outside-month {
        color: #cecece;
    }

    input:focus {
        outline: none;
        cursor: pointer;
    }
}
