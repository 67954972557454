.aside {
    width: 200px;
    padding: 20px;
    margin: 0 20px 0 0;
    border-right: 1px solid #efefef;
    background-color: #ffffff;
    transition: all 0.3s;

    > button {
        position: relative;
        left: -20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0 24px;
        width: calc(100% + 20px);
        padding: 0 16px 0 24px;
        margin-bottom: 20px;
        border: 0;
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
        background-color: #f0f0f0;
        font-size: 18px;
        color: #000000;
        user-select: none;
        cursor: pointer;
        transition: all 0.5s;

        &:hover {
            background-color: #2b92f1;
        }

        > em {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            font-weight: 500;
            overflow: hidden;
        }

        > span {
            display: block;
            font-size: 32px;
            transition: all 0.3s;
        }
    }

    a {
        transition: height 0.3s;
        transition-delay: 0.3s;

        &:hover {
            color: rgba(67, 67, 67, 0.5);
        }
    }

    &[data-open="false"] {
        width: 76px;
        transition: all 0.3s;

        nav div span {
            opacity: 0;
            transition: all 0.3s;
        }

        nav div a {
            display: block;
            height: 0;
            opacity: 0;
            border-left: 0;
            transition: all 0.3s;
        }

        .aside-nav-group-links {
            height: 0;
            border: 0;
            opacity: 0;
        }
    }

    &[data-open="true"] {
        width: 280px;

        > button > span {
            transform: rotateZ(225deg);
        }

        nav div a {
            display: block;
            height: auto;
            opacity: 1;
        }
    }

    &-nav-group {
        padding: 10px 0 0 0;
        border-bottom: 1px solid #efefef;

        &-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0 4px;
            margin: 0 0 10px 0;
            overflow: hidden;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;

            > img {
                width: 32px;
                height: 32px;
            }
        }

        &-links {
            margin-left: 16px;
            padding-left: 16px;
            border-left: 4px solid #efefef;
            transition: opacity 0.3s, height 0.3s;
            transition-delay: 0.3s;

            a {
                font-size: 14px;
            }

            > a.active {
                color: #2b92f1;
                font-weight: 600;
            }

            > * {
                margin: 0 0 12px 0;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
