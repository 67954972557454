.radio-button-group {
    margin: 0 0 40px 0;

    &-title {
        margin: 0 0 16px 0;
        font-size: 16px;

        &-required {
            color: #ff0000;
        }
    }

    &-label {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 35px;
        margin: 0 0 16px 0;
        cursor: pointer;
        user-select: none;

        &:hover input ~ .checkmark {
            background-color: #41a83e33;
        }
    }

    &-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .checkmark {
            background-color: #41a83e;
        }

        &:checked ~ .checkmark:after {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #eee;
        border-radius: 50%;
        transition: all .3s;

        &:after {
            content: '';
            position: absolute;
            display: none;
        }

        &:after {
            top: 4px;
            left: 4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: white;
        }
    }
}
