.coaches-control-page {
    min-height: 80vh;
    margin: 40px 0 40px 0;

    &-control {
        input {
            width: 100%;
            height: 40px;
            margin: 0 0 16px 0;
            border: 1px solid #cecece;
        }

        &-button {
            display: flex;
            justify-content: flex-end;
        }
    }

    &-coach-trainings {
        margin: 0 0 20px 0;
        > h3 {
            margin: 0 0 12px 0;
        }

        > h4 {
            color: red;
            font-weight: 500;
        }

        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 12px;
            margin: 0 0 8px 0;
            border-radius: 4px;
            background: #ffffff;
            font-size: 16px;
        }
    }
}

.add-training {
    > h3 {
        margin: 0 0 12px 0;
        font-size: 20px;
        font-weight: 600;
    }

    > input {
        width: 100%;
        height: 40px;
        margin: 0 0 16px 0;
        border: 1px solid #cecece;
    }
}

.trainings {
    > h3 {
        margin: 0 0 12px 0;
        font-size: 20px;
        font-weight: 600;
    }

    &-list {
        display: flex;
        gap: 0 12px;
        margin: 0 0 40px 0;

        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0 8px;
            padding: 12px 8px;
            border-radius: 12px;
            background-color: #e2e1e1;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                border: 0;
                border-radius: 50%;
                background-color: #ffffff;
                cursor: pointer;
            }
        }
    }
}

.coaches {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;

    tr :is(th, td) {
        padding: 12px 8px;
        vertical-align: middle;
    }

    thead {
        th {
            background-color: #f0efef;
            text-align: left;
            font-weight: 600;
        }
    }

    tbody {
        tr {
            background-color: #ffffff;
        }
    }

    &-coach {
        border-left: 2px solid #888888;

        &-trainings {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px 8px;
            flex-wrap: wrap;
            > span {
                padding: 8px 12px;
                border-radius: 12px;
                background-color: #f3f3f3;
                text-align: center;
            }
        }
    }
}

.update-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #e4e4e47d;
    backdrop-filter: blur(10px);
    overflow: hidden;
    z-index: 777;

    &-window {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 300px;
        width: 400px;
        max-width: 500px;
        padding: 40px;
        background-color: #ffffff;

        &-close-button {
            position: absolute;
            top: 16px;
            right: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border: 0;
            border-radius: 50%;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
        }

        &-coach {
            margin: 0 0 32px 0;
            font-size: 20px;
            font-weight: 600;
        }

        &-trainings {
            width: 100%;
            margin: 0 0 12px 0;

            > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 0 12px;
                padding: 0 0 12px 0;
                margin: 0 0 12px 0;
                border-bottom: 1px solid #e4e4e47d;
            }
        }
    }
}
