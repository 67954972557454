@import "../../../_variables.scss";

$main-color: #006ab6;
$main-color-bg: #006ab64b;

.nush-form-page {
    padding: 20px;
    width: 100%;

    &[data-form-sent="true"] {
        height: 400px;
        overflow: hidden;
    }

    &-alert {
        padding: 20px;
        margin: 0 0 40px 0;
        background-color: $main-color-bg;

        &-title {
            display: flex;
            align-items: center;
            margin: 0 0 16px 0;

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border: 2px solid $main-color;
                border-radius: 50%;
                margin: 0 10px 0 0;
                font-size: 16px;
                font-weight: 600;
                color: $main-color;
            }

            > div:first-child {
                flex: none;
            }

            > h2 {
                color: $main-color;
                font-size: 18px;
                font-weight: 700;

                @media (max-width: $sm) {
                    & {
                        font-size: 18px;
                    }
                }
            }
        }

        &-content {
            font-size: 14px;
            font-weight: 500;
            line-height: 140%;
        }
    }

    .form-row {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 0 20px;
        width: 100%;

        @media (max-width: $md) {
            flex-direction: column;
            gap: 0;
        }
    }

    .form-col-2 {
        width: 100%;
    }

    .form-line {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0 20px;

        > div {
            flex: 1 1 auto;
        }
    }

    &-button-validations {
        margin: 0 0 16px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        color: red;

        > div {
            margin: 0 0 4px 0;
        }
    }

    &-button {
        display: flex;
        justify-content: center;
        margin: 4px 0 4px 0;
    }

    .submit-button {
        padding: 16px 32px;
        margin-bottom: 40px;
        border: 0;
        background-color: $main-color;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;

        &:disabled {
            background-color: #cecece;
        }
        @media (max-width: $sm) {
            & {
                width: 100%;
            }
        }
    }

    .support {
        margin: 0 0 40px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;

        a {
            color: $main-color;
            font-weight: 500;
        }
    }

    /*
        labels
    */

    .custom-input-label,
    .custom-input-without-validation-label,
    .custom-select-label,
    .custom-search-input-label,
    .radio-button-group-title {
        font-size: 14px;
        font-weight: 500;
    }

    .radio-button-group-label,
    .custom-checkbox-label {
        font-size: 14px;
        line-height: 140%;

        &:hover {
            input ~ .checkmark {
                background-color: $main-color-bg;
            }
        }
    }

    /*
        inputs
    */

    input[type="text"] {
        width: 100%;
        height: 40px;
        padding: 0 12px;
        font-size: 14px;
        border: 1px solid $main-color;
    }

    .custom-input-phone-before {
        border: 1px solid $main-color;
        background-color: $main-color-bg;
        font-size: 14px;
        font-weight: 500;
    }

    .custom-select-input {
        border: 1px solid $main-color;
        font-size: 14px;
    }

    .custom-select-input-arrow img {
        width: 10px;
        height: 10px;
    }

    .custom-select-options,
    .custom-search-input-options {
        > div {
            font-size: 14px;
            text-align: left;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background-color: $main-color-bg;
            }
        }
    }

    .radio-button-group-input:checked ~ .checkmark,
    .custom-checkbox-input:checked ~ .checkmark {
        background-color: $main-color;
    }
}
