.custom-input {
    position: relative;

    &-label {
        margin: 0 0 8px 0;
        line-height: 140%;

        &-required {
            color: #ff0000;
        }
    }

    input {
        transition: border .3s;
        margin-bottom: 4px;
    }

    input[data-valid = 'false'] {
        border: 1px solid red !important;
    }

    input:focus {
        outline: none;
    }

    &-phone {
        padding-left: 72px !important;
    }

    &-phone-before {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 64px;
        border: 1px solid #41A83E;
        background-color: #41A83E33;
        color: #000000;
        user-select: none;
    }

    &-validation {
        height: 16px;
        margin: 0 0 12px 0;
        color: red;
        font-size: 12px;
    }
}