.user-page {
    min-height: 80vh;
    margin: 40px 0 40px 0;

    &-users-table {
        width: 100%;
        border-radius: 4px;
        border-collapse: separate;
        border-spacing: 0 8px;
        overflow: hidden;

        thead tr {
            background-color: #f0efef;
        }

        tbody tr {
            background-color: #ffffff;
        }

        tr {
            border-radius: 20px;
            font-size: 16px;

            > * {
                padding: 16px 8px 16px 8px;
            }

            > th {
                font-weight: 600;
                text-align: left;
            }

            > td {
                border-bottom: 1px solid #2b91f112;
            }
        }
    }
}
