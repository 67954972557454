.cwtl-statistics-page {
    &-control {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0 12px;

        .react-datepicker-wrapper {
            margin: 0;
        }

        .custom-date-input-label {
            margin: 0;
        }

        input {
            padding: 8px;
            margin: 0;
            border: 1px solid #2b92f1;
            border-radius: 4px;
        }
    }
}

.cwtl-applications-dashboard {
    > div {
        flex: 1;
        max-width: unset;
    }
}