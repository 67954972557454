.error-page {
    position: relative;
    min-height: 80vh;
    margin: 80px 0 20px 0;

    h1 {
        margin: 0 0 40px 0;
        font-size: 72px;
        font-weight: 700;
    }

    h2 {
        margin: 0 0 40px 0;
        font-size: 32px;
        font-weight: 300;
    }

    h3 {
        margin: 0 0 40px 0;
        font-size: 32px;
        font-weight: 300;
    }

    &-alert {
        padding: 20px;
        border: 1px solid #fc6e6e;
        border-radius: 4px;
        background-color: #ffa5a5;
        font-size: 20px;
        text-transform: uppercase;
    }

    &-bg {
        position: absolute;
        bottom: 0;
        right: 40px;
        
        > img {
            transform: rotateY(180deg);
        }
    }
}