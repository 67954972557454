@import '../../_colors.scss';

.unicef-courses-applications-page {
    min-height: 80vh;
    margin: 40px 0;

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 8px;

        thead tr {
            background-color: #2b91f157;
            overflow: hidden;
        }

        thead tr th:first-child {
            border-left: 4px solid $main-color;
            border-radius: 8px 0 0 8px;
            overflow: hidden;
        }

        thead tr th:last-child {
            border-radius: 0 8px 8px 0;
            overflow: hidden;
        }

        tbody tr {
            background-color: #ffffff;
            overflow: hidden;
        }

        tbody tr td:last-child {
            border-radius: 0 8px 8px 0;
            overflow: hidden;
        }

        tbody tr td:first-child {
            border-left: 4px solid $black-text-color;
            border-radius: 8px 0 0 8px;
            overflow: hidden;
        }

        td,
        th {
            padding: 20px 12px;
            background-color: transparent;
        }

        th {
            font-weight: 500;
            text-align: left;
            color: $black-text-color;
        }

        td {
            background-color: $white-text-color;
            font-weight: 500;
        }
    }

    &-control {
        display: flex;
        justify-content: flex-end;
        gap: 0 8px;
        margin-bottom: 12px;
    }

    &-csv-control {
        display: flex;
        justify-content: center;
    }
}
