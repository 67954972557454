@import "../../../../variables";

.select {
    position: relative;
    width: 100%;
    margin: 0 0 12px 0;
    border: 1px solid $color-light-grey;
    border-radius: 8px;
    background-color: #ffffff;
    font-size: 14px;

    &[data-focused="true"] {
        border: 1px solid $color-main;
    }

    &-label {
         display: block;
        padding: 8px 10px 0 10px;
        color: $color-main;
        font-weight: 500;
        cursor: pointer;

        &-required {
            color: #ff0000;
        }
    }

    &-input {
        display: flex;
        align-items: center;
        min-height: 32px;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
        user-select: none;
        overflow: hidden;

        &[data-disabled="true"] {
            background-color: #f1f1f1;
            cursor: not-allowed;
        }

        &-arrow {
            position: absolute;
            right: 20px;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            transition: all 0.3s;

            &[data-reversed="true"] {
                transform: rotateZ(180deg);
            }

            img {
                width: 12px;
                opacity: 0.5;
            }
        }
    }

    &-options {
        position: absolute;
        top: calc(64px);
        width: 100%;
        max-height: 200px;
        padding: 4px;
        border-radius: 12px;
        background-color: #ffffff;
        overflow-y: scroll;
        overflow-x: hidden;
        overscroll-behavior: contain;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        cursor: pointer;
        z-index: 1;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: $color-main-pale;
        }

        > div {
            padding: 8px 4px;
            font-size: 14px;
            text-align: left;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background-color: rgb(43, 146, 241, 0.2);
            }
        }
    }
}
