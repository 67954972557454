.custom-date-input {

    &-label {
        margin: 0 0 8px 0;

        &-required {
            color: #ff0000;
        }
    }

    > .react-datepicker-wrapper {
        width: 100%;
        margin: 0 0 16px 0;
    }

    .react-datepicker {
        border-radius: 0;
    }

    .react-datepicker__header  {
        border-radius: 0;
        background-color: #41a83e33 !important;
    }

    .react-datepicker__day {
        border-radius: 0;
    }

    .react-datepicker__day--selected {
        background-color: #41a83e;
        color: #000000;
        font-weight: bold;
    }

    input:focus {
        outline: none;
        cursor: pointer;
    }
}