.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #e4e4e47d;
    backdrop-filter: blur(10px);
    overflow: hidden;
    z-index: 777;

    &-window {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 300px;
        max-width: 500px;
        padding: 40px;
        background-color: #ffffff;

        &-close-button {
            position: absolute;
            top: 16px;
            right: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border: 0;
            border-radius: 50%;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
        }

        > h2 {
            margin: 0 0 24px 0;
            font-size: 32px;
            font-weight: 600;
        }

        > p {
            margin: 0 0 32px 0;
            font-size: 20px;
            text-align: center;
        }

        &-submit-button {
            padding: 8px 20px;
            border: 0;
            background-color: #41a83e;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
}
