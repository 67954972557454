.custom-checkbox {
    margin: 0 0 24px 0;

    &-label {
        display: flex;
        align-items: center;
        min-height: 20px;
        position: relative;
        padding-left: 32px;
        cursor: pointer;
        user-select: none;

        &:hover input ~ .checkmark {
            background-color: #41a83e33;
        }
    }

    &-required {
        color: #ff0000;
    }

    &-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            background-color: #41a83e;
        }

        &:checked ~ .checkmark:after {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        transition: all 0.3s;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        &:after {
            left: 7px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
