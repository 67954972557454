@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

@import "./_variables.scss";

html,
body,
* {
    font-family: "Montserrat", sans-serif;
}

* {
    box-sizing: border-box;
}

body {
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
        border: 3px solid #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
}

a {
    color: initial;
    text-decoration: unset;
}

.container {
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}

@media (min-width: $xsm) {
    .container {
        max-width: 90%;
    }
}

@media (min-width: $sm) {
    .container {
        max-width: $container-sm;
    }
}

@media (min-width: $md) {
    .container {
        max-width: $container-md;
    }
}

@media (min-width: $lg) {
    .container {
        max-width: $container-lg;
    }
}

@media (min-width: $xl) {
    .container {
        max-width: $container-xl;
    }
}

@media (min-width: $xxl) {
    .container {
        max-width: $container-xxl;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(-40px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.main-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: auto 5fr;
    background-color: #f6f6f9;
}

.main-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 120px;
    height: 120px;
    background-image: url("./assets/img/illustrations/background-illustration.svg");
    background-repeat: no-repeat;
    background-size: 120px 120px;
    z-index: 1;
}

.page {
    min-height: 80vh;
    margin: 40px 0 40px 0;
}
