.profile-page {
    min-height: 80vh;
    margin: 40px 0 40px 0;

    &-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 36px;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 1px 1px 4px 1px #00000010;

        &-content {
            display: flex;
            align-items: center;
            gap: 0 40px;

            &-img {
                border: 1px solid #cccccc;
                border-radius: 50%;
                overflow: hidden;

                > img {
                    width: 120px;
                    height: 120px;
                    transform: translateY(10px);
                }
            }

            &-info {
                
                &-username {
                    margin: 0 0 20px 0;
                    font-size: 24px;
                    font-weight: 600; 
                    text-transform: uppercase;

                        h3 {
                            display: flex;
                            align-items: center;
                            gap: 0 12px;
                        }

                     span {
                        padding: 4px 12px 4px 12px;
                        border-radius: 8px;
                        font-size: 16px;
                        font-weight: 600;
                        background-color: #e2e1e1;
                    }
                }

                &-email {
                    margin: 0 0 20px 0;
                    font-size: 20px;
                    font-weight: 400;
                }
            }
        }
    }
}
