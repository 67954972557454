.nav {
    background-color: #ffffff;
    border-bottom: 1px solid #efefef;

    &-wrapper {
        height: 8vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &-logo {
        img {
            width: 120px;
        }
    }

    &-login-control {
        display: flex;
        align-items: center;
        gap: 0 12px;

        &-icon {
            position: relative;
            
            > img {
                width: 40px;
                padding: 4px;
                border: 1px solid #efefef;
                border-radius: 50%;
            }

            &-logout {
                position: absolute;
                right: -2px;
                bottom: -4px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #ff5353;
                cursor: pointer;
                transition: transform .3s;

                &:hover {
                    transform: scale(1.1);       
                }

                > a {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 16px;
                    height: 16px;
                    border: 2px solid #ffffff;
                    border-radius: 50px;

                    > span {
                        position: relative;
                        top: -3px;
                        display: block;
                        width: 2px;
                        height: 8px;
                        outline: 2px solid #ff5353;
                        background-color: #ffffff;
                    }
                }
            }
        }

        &-info {
            > div:first-child {
                margin: 0 0 4px 0;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
            }

            > div:last-child {
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}
