.custom-input-without-validation {
    &-label {
        margin: 0 0 8px 0;
        line-height: 140%;

        &-required {
            color: #ff0000;
        }
    }

    > input {
        margin: 0 0 16px 0;

        &:focus {
            outline: none;
            background-color: #f9f9f9;
        }
    }
}
