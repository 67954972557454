@import "../../variables";

.unicef-applications {
    min-height: 80vh;
    margin: 40px 0;

    &-dashboard {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 24px 24px;
        margin: 0 0 20px 0;
    }

    &-export {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0 8px;
        padding: 16px 20px 16px 20px;
        margin-bottom: 16px;
        border-radius: 4px;
        background-color: #ffffff;

        h2 {
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
        }

        div {
            display: flex;
            justify-content: flex-end;
            gap: 0 8px;

            > button,
            > a {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 12px 16px;
                border: 0;
                border-radius: 4px;
                background-color: #2b92f1;
                box-shadow: 1px 1px 4px 1px #00000010;
                color: #ffffff;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
            }
        }
    }

    &-additional-control {
        display: flex;
        justify-content: flex-end;
        gap: 0 8px;
        margin-bottom: 12px;

        > button,
        > a {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 16px;
            border: 0;
            border-radius: 4px;
            background-color: #2b92f1;
            box-shadow: 1px 1px 4px 1px #00000010;
            color: #ffffff;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
        }
    }

    &-filter {
        > div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0 24px;
        }
    }

    &-records {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 8px;
        font-size: 14px;

        tr {
            background-color: #ffffff;
        }

        thead tr:first-child {
            background-color: #2b92f1;
            font-weight: 500;
            color: #ffffff;
        }

        th,
        td {
            vertical-align: middle;
            padding: 12px 8px;
            margin: 0 0 12px 0;
        }

        :is(th, td):nth-child(5) {
            width: 30%;
        }

        :is(th, td):nth-child(2) {
            width: 10%;
        }
    }

    &-limit {
        display: flex;
        justify-content: space-between;
        align-items: center;

        div:first-child {
            span {
                display: inline;
                padding: 4px 8px;
                border-radius: 4px;
                background-color: $color-main;
                font-size: 12px;
                font-weight: 700;
                color: #ffffff;
            }
        }

        div:last-child {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 0 8px;
            margin: 0 0 8px 0;
        }
    }

    &-records-not-found {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 120px;
        background-color: #ffffff;
        font-weight: 500;
    }

    &-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0 8px;
        margin: 28px 0;
        user-select: none;

        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 1px 1px 4px 1px #00000010;
            cursor: pointer;
        }

        > div.active {
            background-color: #2b92f1;
            color: #ffffff;
        }
    }
}

@keyframes grad {
    0% {
        opacity: 0.1;
    }

    25% {
        opacity: 0.15;
    }

    50% {
        opacity: 0.2;
    }

    75% {
        opacity: 0.15;
    }

    100% {
        opacity: 0.1;
    }
}

.report-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #e4e4e47d;
    backdrop-filter: blur(10px);
    overflow: hidden;
    z-index: 777;

    &-window {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 300px;
        max-width: 500px;
        padding: 40px;
        background-color: #ffffff;

        &-close-button {
            position: absolute;
            top: 12px;
            right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border: 0;
            border-radius: 50%;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
        }

        .custom-date-input {
            width: 100%;
        }

        input {
            width: 100%;
            padding: 12px 12px;
            border: 1px solid #2b92f1;
            border-radius: 4px;
        }

        > h2 {
            margin: 0 0 24px 0;
            font-size: 24px;
            font-weight: 600;
            text-align: center;
        }

        > p {
            margin: 0 0 32px 0;
            font-size: 20px;
            text-align: center;
        }

        &-error {
            width: 100%;
            padding: 10px;
            margin: 0 0 12px 0;
            border: #fe4a4a;
            border-radius: 4px;
            background-color: #ffc1c1;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            color: #fe4a4a;
        }

        &-submit-button {
            padding: 8px 20px;
            border: 0;
            border-radius: 4px;
            background-color: #2b92f1;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            color: #ffffff;
            cursor: pointer;

            &:disabled {
                background-color: #cecece;
            }

            &:not(:last-child) {
                margin: 0 0 12px 0;
            }
        }
    }
}
