@import '../../_variables.scss';

.unicef-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    min-height: 10vh;
    margin: 0 0 60px 0;
    background-color: #41a83e;

    @media (max-width: $sm) {
        & {
            margin: 0 0 32px 0;
        }
    }

    &-logo {
        > img {
            width: 120px;
            filter: brightness(0);
        }
    }
}
