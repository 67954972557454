$xsm: 300px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

$color-main: #2b92f1;
$color-main-pale: #2b91f133;

$color-light-grey: #e5e7eb;
