@import "../../_variables.scss";

.unicef-form-page {
    &-title {
        margin: 0 0 40px 0;
        font-size: 36px;
        font-weight: 600;

        @media (max-width: $sm) {
            & {
                margin: 0 0 28px 0;
                font-size: 28px;
                text-align: center;
            }
        }
    }

    &-alert {
        padding: 32px;
        margin: 0 0 40px 0;
        background-color: #41a83e33;

        &-title {
            display: flex;
            align-items: center;
            margin: 0 0 16px 0;

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 28px;
                height: 28px;
                border: 2px solid #000000;
                border-radius: 50%;
                margin: 0 12px 0 0;
                font-size: 20px;
                font-weight: 600;
            }

            > div:first-child {
                flex: none;
            }

            > h2 {
                font-size: 24px;
                font-weight: 700;

                @media (max-width: $sm) {
                    & {
                        font-size: 18px;
                    }
                }
            }
        }

        &-content {
            font-size: 16px;
            font-weight: 400;
            line-height: 120%;
        }
    }

    .form-row {
        display: flex;
        flex-direction: row;
        gap: 40px;
        width: 100%;

        @media (max-width: $sm) {
            flex-direction: column;
            gap: 0;
        }
    }

    .form-col-2 {
        width: 100%;
    }

    .form-line {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0 40px;

        > div {
            flex: 1 1 auto;
        }
    }

    select,
    input[type="text"] {
        width: 100%;
        height: 40px;
        padding: 0 12px;
        font-size: 16px;
        border: 1px solid green;
    }

    .search-input {
        position: relative;

        &-results {
            position: absolute;
            top: calc(40px + 2px);
            width: 100%;
            max-height: 200px;
            background-color: #ffffff;
            overflow-y: scroll;
            overflow-x: hidden;
            overscroll-behavior: contain;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            > div {
                padding: 8px 4px;
                font-size: 16px;
                text-align: left;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    background-color: #41a83e33;
                }
            }
        }
    }

    .partners {
        margin: 0 0 40px 0;
        font-size: 16px;
        color: #41a83e;
        line-height: 120%;
    }

    &-button {
        display: flex;
        justify-content: flex-end;

        > button {
            cursor: pointer;

            &:disabled {
                background-color: #cecece;
            }
            @media (max-width: $sm) {
                & {
                    width: 100%;
                }
            }
        }
    }

    .check {
        margin: 0 0 12px 0;
        font-size: 16px;
        line-height: 120%;

        a {
            color: #41a83e;
            font-weight: 500;
        }
    }

    .submit-button {
        padding: 16px 48px;
        margin-bottom: 40px;
        border: 0;
        background-color: #41a83e;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .support {
        margin: 0 0 40px 0;
        font-size: 16px;
        line-height: 120%;
        font-size: 16px;
        margin: 0 0 40px 0;

        a {
            color: #41a83e;
            font-weight: 500;
        }
    }
}
