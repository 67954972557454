.custom-select {
    position: relative;

    &-label {
        margin: 0 0 8px 0;
        line-height: 140%;
    }

    &-input {
        position: relative;
        height: 40px;
        padding: 12px 40px 12px 12px;
        margin: 0 0 32px 0;
        border: 1px solid #41a83e;
        cursor: pointer;
        user-select: none;
        overflow: hidden;

        &[data-disabled="true"] {
            background-color: #f1f1f1;
            cursor: not-allowed;
        }

        &-arrow {
            position: absolute;
            right: 20px;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            transition: all 0.3s;

            &[data-reversed="true"] {
                transform: rotateZ(180deg);
            }

            img {
                width: 16px;
                height: 16px;
            }
        }
    }

    &-options {
        position: absolute;
        top: 100%;
        width: 100%;
        max-height: 200px;
        padding: 4px;
        background-color: #ffffff;
        overflow-y: scroll;
        overflow-x: hidden;
        overscroll-behavior: contain;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        cursor: pointer;
        z-index: 1;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        > div {
            padding: 8px 4px;
            font-size: 16px;
            text-align: left;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background-color: #41a83e33;
            }
        }
    }
}
