.search-select {
    position: relative;

    &-label {
        margin: 0 0 8px 0;

        &-required {
            color: #ff0000;
        }
    }

    &-input {
        margin: 0 0 32px 0;

        &[data-disabled='true'] {
            background-color: #f1f1f1;
            cursor: not-allowed;
        }

        &::placeholder {
            color: #000000;
        }

        &:focus {
            outline: none;
            background-color: #f9f9f9;
        }

        &:focus::placeholder {
            color: #c6c6c6;
        }
    }

    &-options {
        position: absolute;
        top: 64px;
        width: 100%;
        max-height: 200px;
        background-color: #ffffff;
        overflow-y: scroll;
        overflow-x: hidden;
        overscroll-behavior: contain;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        z-index: 1;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        > div {
            padding: 8px 4px;
            font-size: 16px;
            text-align: left;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background-color: #41a83e33;
            }
        }
    }
}
