.applications-amount-card {
    position: relative;
    min-width: 280px;
    max-width: calc(25% - 24px);
    padding: 32px;
    border-left: 4px solid #2b92f1;
    border-radius: 4px;
    background: linear-gradient(15deg, #ffffff, #ffffff, #2b91f157 150%);
    box-shadow: 1px 1px 4px 1px #00000010;
    overflow: hidden;
    transition: all 0.3s;

    &.green {
        border-left: 4px solid #519d45;
        background: linear-gradient(15deg, #ffffff, #ffffff, rgb(43 241 51 / 34%) 150%);
    }

    &.orange {
        border-left: 4px solid #f1b12b;
        background: linear-gradient(15deg, #ffffff, #ffffff, rgb(241 163 43 / 34%) 150%);
    }

    &.pink {
        border-left: 4px solid #ff9899;
        background: linear-gradient(15deg, #ffffff, #ffffff, #ff989966 150%);
    }

    &:hover {
        transform: translateY(4px);
    }

    > span {
        position: absolute;
        right: -10px;
        bottom: -1px;
        font-size: 142px;
        font-weight: 700;
        line-height: 0.74;
        font-family: 'Ubuntu';
        user-select: none;
        outline: 0;
        opacity: 0.1;
    }

    > h2 {
        margin: 0 0 16px 0;
        font-weight: 700;
        font-size: 16px;
    }

    &-data-main {
        display: flex;
        align-items: center;
        gap: 0 8px;
        font-weight: 300;
        font-size: 28px;

        > button {
            padding: 4px;
            border: unset;
            border-radius: 4px;
            background-color: transparent;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                filter: opacity(0.5);
            }

            &:active {
                transform: scale(0.95);
            }

            > img {
                height: 28px;
            }
        }
    }

    &-data-minor {
        margin: 12px 0 12px 0;
        font-size: 20px;
        font-weight: 300;

        > span {
            font-weight: 600;
            font-size: 14px;
        }
    }

    > p {
        margin: 20px 0 0 0;
        color: #888888;
    }
}
