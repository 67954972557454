.button {
    position: relative;
    padding: 8px 20px;
    border: 1px solid #2b92f1;
    border-radius: 4px;
    background-color: #2b92f1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    overflow: hidden;
    transition: all .3s;

    &:disabled {
        background-color: #cecece;
        border: 1px solid #cecece;
    }

    &:hover {
        background-color: #ffffff;
        color: #2b92f1;
    }

    &:active {
        border: 1px solid #17911f;
        color: #17911f;
    }
}