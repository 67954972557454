.footer {
    min-height: 20vh;
    background-color: #000000;
    color: #ffffff;
    overflow-x: hidden;

    &-divider {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 40px;
        padding: 40px 0;
    }

    &-title {
        font-size: 24px;
        font-weight: 100;
    }

    &-info {
        &-support-title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 8px 0;
        }

        &-support-email {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0 8px;

            img {
                width: 16px;
            }

            a {
                font-size: 16px;
                font-weight: 200;
                color: #ffffff;
            }
        }
    }

    &-social {
        display: flex;
        align-items: center;
        gap: 0 24px;

        img {
            width: 32px;
            transition: all .3s;

            &:hover {
                opacity: .7;
            }
        }
    }
}
