.not-found-page {
    min-height: 80vh;
    margin: 40px 0 40px 0;

    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 60vh;

        > h1 {
            margin: 0 0 40px 0;
            font-size: 240px;
            font-weight: 200;
            color: #2b91f1;

            span {
                display: inline-flex;
                animation-name: jump;
                animation-duration: 3s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;

                &:nth-child(2) {
                    animation-delay: 0.75s;
                }

                &:nth-child(3) {
                    animation-delay: 1.5s;
                }
            }
        }

        h2 {
            font-size: 36px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}

@keyframes jump {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(20px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}
