.home-page {
    min-height: 80vh;
    margin: 40px 0 40px 0;

    &-title {
        > h1 {
            margin: 24px 0 40px 0;
            font-size: 36px;
            font-weight: 600;
            animation: fadeIn 2s;
        }
    }

    &-info {
        display: grid;
        grid-template-columns:  1fr 1fr;
        gap: 0 32px;

        > div {
            padding: 24px;
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 1px 1px 4px 1px #00000010;
            font-size: 16px;
            line-height: 1.2;
            animation: fadeIn 1s;
        }
    }

    &-desription {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        > p {
            margin: 0 0 12px 0;
        }
    }

    &-updates {
        > h3 {
            margin: 0 0 12px 0;
            font-size: 20px;
            font-weight: 600;
        }

        > div {
            max-height: 140px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 4px;
                background-color: #efefef;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: #bababa;
            }

        }

        &-update {
            margin: 0 0 12px 0;
            > h6 {
                display: inline-block;
                padding: 2px 8px;
                margin: 0 0 4px;
                border-radius: 4px;
                background-color: #2b92f1;
                font-size: 12px;
                font-weight: 600;
                color: #ffffff;
                user-select: none;
            }
        }
    }
}