@import "../../../../variables";

.search-with-select {
    position: relative;
    width: 100%;
    margin: 0 0 12px 0;
    border: 1px solid $color-light-grey;
    border-radius: 8px;
    background-color: #ffffff;
    font-size: 14px;

    &[data-focused="true"] {
        border: 1px solid $color-main;
    }

    &-label {
        display: block;
        padding: 8px 10px 0 10px;
        color: $color-main;
        font-weight: 500;
        cursor: pointer;

        &-required {
            color: #ff0000;
        }
    }

    &-input {
        border: 0;

        input {
            min-height: 32px;
            width: 100%;
            border: 0;
            background-color: transparent;
            font-size: 14px;

            &[data-disabled="true"] {
                background-color: #f1f1f1;
                cursor: not-allowed;
            }

            &::placeholder {
                color: #000000;
            }

            &:focus {
                border: 0;
                outline: none;
            }

            &:focus::placeholder {
                color: #c6c6c6;
            }
        }
    }

    &-options {
        position: absolute;
        top: 64px;
        width: 100%;
        max-height: 200px;
        padding: 4px;
        border-radius: 8px;
        background-color: #ffffff;
        overflow-y: scroll;
        overflow-x: hidden;
        overscroll-behavior: contain;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        z-index: 1;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        > div {
            padding: 8px 4px;
            text-align: left;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background-color: $color-main-pale;
            }
        }
    }
}
